// 激活相关接口
import { instance } from '@/utils/http.js'

// 获取验证码
export const getverify = function (params) {
  return instance({
    url: '/api/v1/stucard/verify',
    method: 'get',
    params
  })
}

// 激活
export const toActivate = function (data) {
  return instance({
    url: '/api/v1/stucard/activate',
    method: 'post',
    data
  })
}

// 年级
export const getGrade = function (params) {
  return instance({
    url: '/api/v1/stucard/grade',
    method: 'get',
    params
  })
}

// 地区
export const getDis = function (params) {
  return instance({
    url: '/api/v1/stucard/district',
    method: 'get',
    params
  })
}


export const getDistrict = function (params) {
  return instance({
    url: '/api/v1/user/register_paper_district',
    method: 'get',
    params
  })
}




//验证验证码
export const verifyUser = function (data) {
  return instance({
    url: '/api/v1/user/verify',
    method: 'post',
    data
  })
}


export const getCity = function (params) {
  return instance({
    url: '/api/v1/user/get_city_tree',
    method: 'get',
    params
  })
}
//开通科目
export const getSubject= function (params) {
  return instance({
    url: '/api/v1/stucard/subject',
    method: 'get',
    params
  })
}





